import commonEN from './en/common/common.json';
import errorsEN from './en/common/errors.json';
import apiErrorsEn from './en/common/apiErrors.json';

import fileUploaderEN from './en/components/fileUploader.json';
import paymentBreakdownEN from './en/components/paymentBreakdown.json';
import betaAccessModalEN from './en/components/betaAccessModal.json';
import submissionOwnerTag from './en/components/submissionOwnerTag.json';
import genericModalWithErrorCode from './en/components/genericModalWithErrorCode.json';

import authenticationEN from './en/authentication.json';
import navigationEN from './en/navigation.json';
import landingPageEN from './en/landingPage.json';
import termsOfUsePageEN from './en/termsOfUsePage.json';
import privacyStatementPageEN from './en/privacyStatementPage.json';
import contactUsPageEN from './en/contactUsPage.json';
import notificationsEN from './en/notifications.json';
import submissionSearchPageEN from './en/submissionSearchPage.json';

import projectsPageEN from './en/projects/projectsPage.json';
import projectOverviewPageEN from './en/projects/projectOverviewPage.json';
import createProjectPageEN from './en/projects/createProjectPage.json';
import authoriseProjectRemovalPageEN from './en/projects/authoriseProjectRemovalPage.json';
import authoriseProjectPageEN from './en/projects/authoriseProjectPage.json';
import projectDetailsPageEN from './en/projects/projectDetailsPage.json';
import editProjectEN from './en/projects/editProject.json';
import projectInfoFormEN from './en/projects/projectInfoForm.json';
import projectAddressEN from './en/projects/projectAddress.json';
import projectInfoCardEN from './en/projects/projectInfoCard.json';
import projectMembersEN from './en/projects/projectMembers.json';
import memberInvitePageEN from './en/projects/memberInvitePage.json';
import requestAppointmentPageEN from './en/projects/requestAppointmentPage.json';
import confirmAppointmentPageEN from './en/projects/confirmAppointmentPage.json';
import replaceProjectCoordinatorEN from './en/projects/replaceProjectCoordinator.json';
import topCscRequirementsPageEN from './en/projects/topCscRequirementsPage.json';

import rolesEN from './en/projects/roles.json';
import permitEN from './en/projects/permit.json';
import responsibilitiesEN from './en/projects/responsibilities.json';
import agenciesEN from './en/agencies.json';
import agenciesAbbreviationEN from './en/agenciesAbbreviation.json';
import appointmentEN from './en/appointment.json';
import submissionsEN from './en/submissions/submissions.json';

import paymentComponentsEN from './en/payments/paymentComponents.json';
import myPaymentsPageEN from './en/payments/myPaymentsPage.json';
import projectPaymentsPageEN from './en/payments/projectPaymentsPage.json';
import guestPaymentsEN from './en/payments/guestPayments.json';
import paymentConfirmationPageEN from './en/payments/paymentConfirmationPage.json';
import selectPaymentsPageEN from './en/payments/selectPaymentsPage.json';
import reviewPaymentsPageEN from './en/payments/reviewPaymentsPage.json';
import delegatePaymentsPageEN from './en/payments/delegatePaymentsPage.json';
import makePaymentPageEN from './en/payments/makePaymentPage.json';
import paymentSelectionTextEN from './en/payments/paymentSelectionText.json';
import payNowPageEN from './en/payments/payNowPage.json';
import delegatedPaymentsPageEN from './en/payments/delegatedPaymentsPage.json';
import profilePageEN from './en/profile/profilePage.json';
import assistantEN from './en/assistant.json';
import submissionWorkspacePageEN from './en/submissions/submissionWorkspacePage.json';
import selectSubmissionPageEN from './en/submissions/selectSubmissionPage.json';
import checkSubmissionEligibilityPageEN from './en/submissions/checkSubmissionEligibilityPage.json';
import identifyGatewayPageEN from './en/submissions/identifyGatewayPage.json';
import confirmGatewayPageEN from './en/submissions/confirmGatewayPage.json';
import indicateSubmissionDetailsEN from './en/submissions/indicateSubmissionDetailsPage.json';
import submissionDetailsPagePermitEN from './en/submissions/submissionDetailsPagePermit.json';
import createSubmissionPageEN from './en/submissions/createSubmissionPage.json';
import selectRequiredSectionsPageEN from './en/submissions/selectRequiredSectionsPage.json';
import submissionDetailsPageEN from './en/submissions/submissionDetailsPage.json';
import editSubmissionPageEN from './en/submissions/editSubmissionPage.json';
import formFieldsBuilderEN from './en/submissionForms/formFieldsBuilder.json';
import submissionStateEN from './en/submissions/submissionState.json';
import initiateAmendmentEN from './en/submissions/initiateAmendment.json';
import initiateReapplicationEN from './en/submissions/initiateReapplication.json';
import deleteSubmissionEN from './en/submissions/deleteSubmission.json';
import withdrawSubmissionEN from './en/submissions/withdrawSubmission.json';
import revokePermitEN from './en/submissions/revokePermit.json';
import declarationFormEN from './en/submissions/declarationForm.json';
import assignProjectPartyEN from './en/submissions/assignProjectParty.json';
import viewResponsesPageEN from './en/submissions/viewResponsesPage.json';
import viewSubmissionActivityEN from './en/submissions/viewSubmissionActivity.json';
import recursiveDropdownLabelEN from './en/recursiveDropdownLabel.json';
import submissionTypeEN from './en/submissions/submissionType.json';
import bankTransferPageEN from './en/payments/bankTransferPage.json';
import authoriseProjectMemberRemovalPageEN from './en/projects/authoriseProjectMemberRemovalPage.json';
import submissionMilestoneEN from './en/submissions/submissionMilestone.json';
import editRelatedStructuralPlansEN from './en/submissions/editRelatedStructuralPlans.json';
import lightweightBimViewerEN from './en/submissions/lightweightBimViewer.json';
import modelCheckPageEN from './en/projects/modelCheckPage.json';
import indicateInspectionDetailsEN from './en/siteInspections/indicateInspectionDetails.json';
import siteInspectionBookingDetailsEN from './en/siteInspections/BookingDetails.json';
import inspectionSelectSubmissionEN from './en/siteInspections/InspectionSelectSubmission.json';
import inspectionActionPanelEN from './en/siteInspections/ActionPanel.json';

export const resources = {
  en: {
    common: commonEN,
    errors: errorsEN,
    apiErrors: apiErrorsEn,
    authentication: authenticationEN,
    navigation: navigationEN,
    landingPage: landingPageEN,
    termsOfUsePage: termsOfUsePageEN,
    privacyStatementPage: privacyStatementPageEN,
    contactUsPage: contactUsPageEN,
    submissionSearchPage: submissionSearchPageEN,
    paymentComponents: paymentComponentsEN,
    myPaymentsPage: myPaymentsPageEN,
    projectPaymentsPage: projectPaymentsPageEN,
    projectsPage: projectsPageEN,
    projectOverviewPage: projectOverviewPageEN,
    createProjectPage: createProjectPageEN,
    authoriseProjectRemovalPage: authoriseProjectRemovalPageEN,
    authoriseProjectPage: authoriseProjectPageEN,
    projectDetailsPage: projectDetailsPageEN,
    projectAddress: projectAddressEN,
    editProject: editProjectEN,
    projectInfoForm: projectInfoFormEN,
    projectInfoCard: projectInfoCardEN,
    projectMembers: projectMembersEN,
    memberInvitePage: memberInvitePageEN,
    guestPayments: guestPaymentsEN,
    paymentConfirmationPage: paymentConfirmationPageEN,
    notifications: notificationsEN,
    selectPaymentsPage: selectPaymentsPageEN,
    reviewPaymentsPage: reviewPaymentsPageEN,
    delegatePaymentsPage: delegatePaymentsPageEN,
    makePaymentPage: makePaymentPageEN,
    payNowPage: payNowPageEN,
    delegatedPaymentsPage: delegatedPaymentsPageEN,
    paymentSelectionText: paymentSelectionTextEN,
    roles: rolesEN,
    permit: permitEN,
    responsibilities: responsibilitiesEN,
    agencies: agenciesEN,
    agenciesAbbreviation: agenciesAbbreviationEN,
    submissions: submissionsEN,
    submissionType: submissionTypeEN,
    recursiveDropdownLabel: recursiveDropdownLabelEN,
    assistant: assistantEN,
    profilePage: profilePageEN,
    submissionWorkspacePage: submissionWorkspacePageEN,
    selectSubmissionPage: selectSubmissionPageEN,
    checkSubmissionEligibilityPage: checkSubmissionEligibilityPageEN,
    submissionDetailsPagePermit: submissionDetailsPagePermitEN,
    createSubmissionPage: createSubmissionPageEN,
    submissionDetailsPage: submissionDetailsPageEN,
    editSubmissionPage: editSubmissionPageEN,
    requestAppointmentPage: requestAppointmentPageEN,
    confirmAppointmentPage: confirmAppointmentPageEN,
    fileUploader: fileUploaderEN,
    paymentBreakdown: paymentBreakdownEN,
    formFieldsBuilder: formFieldsBuilderEN,
    submissionState: submissionStateEN,
    initiateAmendment: initiateAmendmentEN,
    initiateReapplication: initiateReapplicationEN,
    deleteSubmission: deleteSubmissionEN,
    withdrawSubmission: withdrawSubmissionEN,
    revokePermit: revokePermitEN,
    declarationForm: declarationFormEN,
    assignProjectParty: assignProjectPartyEN,
    appointment: appointmentEN,
    viewResponsesPage: viewResponsesPageEN,
    viewSubmissionActivity: viewSubmissionActivityEN,
    betaAccessModal: betaAccessModalEN,
    submissionOwnerTag: submissionOwnerTag,
    genericModalWithErrorCode: genericModalWithErrorCode,
    indicateSubmissionDetailsPage: indicateSubmissionDetailsEN,
    bankTransferPage: bankTransferPageEN,
    authoriseProjectMemberRemovalPage: authoriseProjectMemberRemovalPageEN,
    replaceProjectCoordinator: replaceProjectCoordinatorEN,
    topCscRequirementsPage: topCscRequirementsPageEN,
    submissionMilestone: submissionMilestoneEN,
    identifyGatewayPage: identifyGatewayPageEN,
    confirmGatewayPage: confirmGatewayPageEN,
    selectRequiredSectionsPage: selectRequiredSectionsPageEN,
    editRelatedStructuralPlans: editRelatedStructuralPlansEN,
    lightweightBimViewer: lightweightBimViewerEN,
    modelCheckPage: modelCheckPageEN,
    indicateInspectionDetails: indicateInspectionDetailsEN,
    siteInspectionBookingDetails: siteInspectionBookingDetailsEN,
    inspectionSelectSubmission: inspectionSelectSubmissionEN,
    inspectionActionPanel: inspectionActionPanelEN,
  },
};

import classNames from 'classnames';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { CardList } from '../Card/CardList';
import { DescriptionList } from '../DescriptionList/DescriptionList';
import { TextLink } from '../TextLink/TextLink';
import { formatDateWithLongMonth } from 'utils/date';

export interface ITimeline {
  eventNodes: React.ReactNode[];
  expandedView?: boolean;
}

type EmphasisLevel = 'high' | 'medium' | 'low';

export interface ICardListContent {
  title: string;
  items: {
    label: string;
    data: React.ReactNode;
  }[];
  secondaryLink?: React.ReactNode;
  primaryAction?: React.ReactNode;
  notification?: React.ReactNode;
}

export interface IEventRenderData {
  emphasisLevel?: EmphasisLevel;
  /** Shown when first item of timeline */
  primaryIcon: React.ReactNode;
  /** Shown when not first item of timeline */
  secondaryIcon: React.ReactNode;
  title: string;
  description?: string;
  date: Date;
  link?: {
    label: React.ReactNode;
    onClick: () => void;
    alternateLabel?: React.ReactNode;
  };
  descriptionListContent?: {
    heading: string;
    items: {
      label: string;
      data: React.ReactNode;
    }[];
  };
  cardListContent?: ICardListContent[];
  collapsedContent?: React.ReactNode;
  additionalContent?: React.ReactNode;
  forceShowDescription?: boolean;
  showLink?: boolean;
}

export interface IEventMeta {
  index: number;
  isLastEvent: boolean;
  expandedView: boolean;
}

export const Timeline: React.FC<ITimeline> = ({ eventNodes }) => {
  return <div className={classNames('flex flex-col space-y-0.5')}>{eventNodes}</div>;
};

export const TimelineEvent: React.FC<IEventRenderData & IEventMeta> = ({
  index,
  isLastEvent,
  expandedView,
  emphasisLevel = 'low',
  primaryIcon,
  secondaryIcon,
  title,
  date,
  description,
  forceShowDescription = false,
  showLink = false,
  link,
  descriptionListContent,
  cardListContent,
  collapsedContent,
  additionalContent,
}) => (
  <div className="flex space-x-3">
    <div className="flex flex-col space-y-0.5 items-center">
      <div className="flex-shrink-0 h-6 w-6">{index === 0 ? primaryIcon : secondaryIcon}</div>

      {!isLastEvent && <div className="w-px h-full bg-gray-30/60" />}
    </div>
    <div className="flex flex-col space-y-6 pb-8 grow">
      <div className="flex flex-col space-y-3">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col space-y-1">
            <div
              className={classNames('text-gray-90', {
                'heading-6-semibold': emphasisLevel === 'high' || index === 0 || expandedView,
                'sub-heading': emphasisLevel === 'medium' && index !== 0 && !expandedView,
                'label-large': emphasisLevel === 'low' && index !== 0 && !expandedView,
              })}
            >
              {title}
            </div>
            <div className="label-regular text-gray-60">{formatDateWithLongMonth(date)}</div>
          </div>

          {(!expandedView || forceShowDescription) && index === 0 && description && (
            <div className="body-small text-gray-70">{description}</div>
          )}
        </div>

        {(expandedView || index === 0) && link && (
          <div className="w-max">
            <TextLink onClick={link.onClick}>{link.label}</TextLink>
          </div>
        )}
        {!expandedView && index !== 0 && collapsedContent}
        {!expandedView && index !== 0 && showLink && link && (
          <div className="w-max">
            <TextLink onClick={link.onClick}>{link.alternateLabel ?? link.label}</TextLink>
          </div>
        )}
      </div>

      <ErrorBoundary>
        {(expandedView || index === 0) &&
          cardListContent &&
          (Array.isArray(cardListContent) ? cardListContent : [cardListContent]).map(
            ({ title, notification, items, primaryAction, secondaryLink }, index) => (
              <CardList
                key={index}
                title={title}
                notification={notification}
                dataItems={items}
                primaryAction={!expandedView ? primaryAction : null}
                secondaryLink={!expandedView ? secondaryLink : null}
              />
            ),
          )}
        {(expandedView || index === 0) && descriptionListContent && (
          <DescriptionList header={descriptionListContent.heading} items={descriptionListContent.items} />
        )}
        {(expandedView || index === 0) && additionalContent}
      </ErrorBoundary>
    </div>
  </div>
);

export const defaultFeatureFlags = {
  hasLoaded: false,

  // devtools
  enableSandbox: false,
  showFormBuilder: false,
  enableQuickFilePolling: false,
  enableSubmissionHelpers: false,
  enableQuickSubmissionLock: false,
  enableMockAccountDashboard: false,
  enableToggleFeatureFlagPanel: false,

  enableAlphaUsers: false,
  restrictAccessToBeta: false,

  // already enabled
  enableDspV3: false,
  enableConsolidatedDeclaration: false,
  enableCreateSubmissionV2_DSP: false,
  enableCreateSubmissionV2_35Gateway: false,
  enableCreateSubmissionV2_FullDemolition: false,
  enableCreateSubmissionV2_EWPlanApplication: false,
  enableCreateSubmissionV2_StPlanPermit: false,
  enableCreateSubmissionV2_PermitToCarrySTWorks: false,
  enableBCALodgementStPlanPermit: false,
  enableBCALodgementDifferentSTWORKSQPNotification: false,
  enableReplaceTCAP: false,
  enableTopCscRequirementsTable: false,
  enableCreateProjectV2: false,
  enableEditProfileV2: false,
  enableDeletionOfNonBimFiles: false,
  enableFullDemolitionV3: false,
  enableFullDemolitionV3Enhancements: false,
  enableValidLicenseCheck: false,
  enableRemovePEStructRole: false,
  enableEditAdditionalDetailsJtc: false,
  enableShowAutoAssignedProjectParty: false,
  enableWDBannerEnhancement: false,
  enableSelectSubmissionQp: false,
  enableEditRelatedStructuralPlans: false,
  enableFullDemolitionBannerStWorks: false,
  enableRemovalOfAppointedResponsibilities: false,
  enableStPlanAndPermitSameQp: false,
  enableRequireChangeForPlanAmendment: false,
  enableCscPubAssignProjectParty: false,
  enableFullDemolitionURAClearanceCheck: false,
  enableReviewIfWDSectionUnchanged: false,
  enableConcurrentCreationOfDGCGPG: false,
  enableLoginPageV2: false,

  // not enabled in prd
  enableMCPreCheck: false,
  enableSiteInspection: false,
  enableSiteInspectionSoftBooking: false,
  enableFixedInstallationIndependentTechclearance: false,
  enableNewFormAvailableBanner: false,
  enablePermitRevocation: false,
  enableDeleteProject: false,
  enableAppointmentsPageV2: false,
  enableSubmissionAopoStatus: false,
  enableCreationWizardV2ForDG: false,
  enableCreationWizardV2ForPG: false,
  enableCreationWizardV2ForCG: false,
  enableSingpassEditCompanyDetails: false,
  enableDigitalSigningTimeout: false,
  enableEnhancePlanAppApplicableSection: false,
  enableEnhanceFullDemolitionNotRequiredSection: false,
  enableWithdrawSubmission: false,
  enableWithdrawResubmission: false,
  enableWithdrawAmendment: false,
  enableDGV8: false,
  enableCGV8: false,
  enableCGV9: false,
  enableCGV10: false,
  enableInfoLink: false,
  enableFullDemolitionEnhancementsFor35Gateway: false,
  enableQc: false,
  enableQcGatekeep: false,
};

export type FeatureFlags = typeof defaultFeatureFlags;
